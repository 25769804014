import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));  // Tasodifiy indeksni tanlash
        [array[i], array[j]] = [array[j], array[i]];  // Elementlarni almashtirish
    }
    return array;
}

const slice = createSlice({
    name: 'product',
    initialState: {products: [], filtered_products: []},
    reducers: {
        getAllProduct: (state, action) => {
            // Tasodifiylashtirish va mahsulotlarni saqlash
            state.products = action.payload;
            state.products.results = shuffle(action.payload.results);  // Tasodifiylashtirilgan mahsulotlar
        },
        getAllProductNextPage: (state, action) => {
            state.products.next = action.payload.next;
            state.products.results = [
                ...state.products.results,
                ...shuffle(action.payload.results)  // Yangi sahifa uchun tasodifiylashtirilgan mahsulotlar
            ];
        },
        getAllFilteredProducts: (state, action) => {
            state.products = action.payload;
            state.products.results = shuffle(action.payload.results);
        },
        getAllProductTitleFilter: (state, action) => {
            state.products = action.payload
        }
    }
})

export const getProducts = () => apiCall({
    url: 'products/',
    method: 'GET',
    onSuccess: slice.actions.getAllProduct.type
})

export const getProductNextPage = (nextUrl) => apiCall({
    url: 'products/?' + nextUrl,
    method: 'GET',
    onSuccess: slice.actions.getAllProductNextPage.type
})

export const getProductTitleFilter = (title, lang) => apiCall({
    url: 'products/?search=' + title + '&lang=' + lang,
    method: 'GET',
    onSuccess: slice.actions.getAllProductTitleFilter.type
})

export const getProductTitleFilterCategories = (cat, title, lang) => apiCall({
    url: 'products/?categories=' + cat + '&search=' + title + '&lang=' + lang,
    method: 'GET',
    onSuccess: slice.actions.getAllProductTitleFilter.type
})

export const getProductTitleFilterChildCategories = (child, title, lang) => apiCall({
    url: 'products/?childcategories=' + child + '&search=' + title + '&lang=' + lang,
    method: 'GET',
    onSuccess: slice.actions.getAllProductTitleFilter.type
})

export const getFilteredProducts = (id) => apiCall({
    url: 'products/?categories=' + id,
    method: 'GET',
    onSuccess: slice.actions.getAllFilteredProducts.type,
})

export const getFilteredChildProducts = (id) => apiCall({
    url: 'products/?childcategories=' + id,
    method: 'GET',
    onSuccess: slice.actions.getAllFilteredProducts.type,
})

export default slice.reducer